.ProdDetails .member-price + .pdpPriceRating {
    padding-bottom: 28px;
}

.frasers-plus-breakdown {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    padding-bottom: 28px;

    .frasers-plus-breakdown-cta {
        border-bottom: 1px solid var(--frasers-member-price-color);
        cursor: pointer;
    }

    @media (max-width: 767px) {
        padding: 0 15px 28px;
    }

    &-desc {
        font-size: 16px;
        font-weight: var(--fw-bold, 700);
        color: var(--frasers-member-price-color, #8400d1);
        margin: 0;
    }
}
