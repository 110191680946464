.sash-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    gap: 8px;
    flex-direction: column;
    margin-top: 8px;
    z-index: 901;
}

.product-sash {
    font-size: 9px;
    padding: 5px;
    line-height: 9px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    width: max-content;
}

[data-sash-touchpoint="PLP"] {
    position: absolute;
    top: 8px;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

[data-sash-touchpoint="PLP"] .product-sash,
body.searchFocus .product-sash,
body.modal-open .product-sash {
    z-index: 9;
}

span.product-sash {
    pointer-events: none;
}

[data-sash-details].hidden {
    display: none;
}

[data-sash-inside-pdp-info="true"] {
    .pdp-sash-container {
        position: relative;
        float: left;
        width: 100%;
        min-height: 23px;
        max-height: 23px;
        margin-bottom: 14px;
        z-index: 1;

        .sash-container {
            top: 50%;
            left: 0;
            translate: 0 -50%;
            display: flex;
            gap: 8px;
            flex-direction: row;
            margin: 0;
        }

        .product-sash {
            display: block;
            position: static;
            line-height: normal;
            pointer-events: auto;
            white-space: nowrap;
        }
    }

    @media (max-width: 767px) {
        .pdp-sash-container {
            display: none;
        }
    }

    @media (min-width: 768px) {
        #productImages .sash-container,
        #productImageContainer .sash-container {
            display: none;
        }
    }
}

@media (min-width: 1022px) {
    [data-sash-touchpoint="PLP"] .product-sash {
        font-size: 12px;
        line-height: 22px;
        padding: 5px 7px;
    }
}
