.basket-urgency-message {
    display: flex;
    align-items: center;
    margin: 20px 0;
    padding: 20px;
    background: var(--site-white, #fff);

    .global-icon {
        margin-right: 8px;
    }

    &-container {
        display: flex;
        gap: var(--spacing-unit, 4px);

        @media (max-width: 767px) {
            flex-direction: column;
            gap: 0;
        }
    }

    &-header,
    &-text {
        font-size: var(--body-font-size, 14px);
        line-height: 22px;
        margin: 0;
        text-transform: none;

        @media (max-width: 767px) {
            line-height: 18px;
        }
    }

    &-header {
        font-weight: var(--fw-bold, 700);
    }
}

#divBagItems .basket-urgency-message {
    padding: 12px 24px;
    margin: 0;
    background-color: var(--site-grey, #f7f7f7);

    .global-icon {
        svg {
            width: 32px;
            height: 27px;
        }
    }

    &-container {
        flex-direction: column;
        gap: 0;
    }
}
