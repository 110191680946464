.sitewide-banner-enabled {
    --sitewide-banner-height: 20px;

    &.sitewide-banner-two-lines {
        --sitewide-banner-height: 40px;
    }
}

.sitewide-banner-container {
    height: var(--sitewide-banner-height, 0);
    background-color: var(--sitewide-banner-background-color, #000);
    border-bottom: var(--sitewide-banner-border, none);
}

.appOnly .sitewide-banner-container {
    position: sticky;
    top: 0;
    z-index: 4;
}

.sitewide-banner {
    opacity: 1;
    width: 100%;
    height: 100%;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.sitewide-banner > .sitewide-banner-slide {
    position: absolute;
    opacity: 0;
    text-align: center;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sitewide-banner > .sitewide-banner-slide a {
    text-decoration: none;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex: 1 1 auto;
    background-color: transparent;
    line-height: 15px;
    font-size: 13px;
    color: var(--sitewide-banner-font-color, #fff);

    &:first-child {
        font-weight: 600;
    }

    &:last-child {
        align-items: flex-start;
    }

    &:only-child {
        align-items: center;
    }
}

.sitewide-banner .sitewide-banner-slide:nth-child(1) {
    animation-delay: 0s;
}

.sitewide-banner .sitewide-banner-slide:nth-child(2) {
    animation-delay: 4s !important;
}

.sitewide-banner .sitewide-banner-slide:nth-child(3) {
    animation-delay: 8s !important;
}

.sitewide-banner .sitewide-banner-slide:nth-child(4) {
    animation-delay: 12s !important;
}

.sitewide-banner .sitewide-banner-slide:nth-child(5) {
    animation-delay: 16s !important;
}

/* ===== One Slide ===== */
.sitewide-banner .sitewide-banner-slide:first-child:nth-last-child(1),
.sitewide-banner .sitewide-banner-slide:first-child:nth-last-child(1) ~ .sitewide-banner-slide {
    animation: 1s autoplay infinite linear;
}

@keyframes autoplay {
    0% {
        opacity: 1;
        z-index: 1;
    }

    100% {
        opacity: 1;
        z-index: 1;
    }
}

/* ===== Two Slides ===== */
.sitewide-banner .sitewide-banner-slide:first-child:nth-last-child(2),
.sitewide-banner .sitewide-banner-slide:first-child:nth-last-child(2) ~ .sitewide-banner-slide {
    animation: 8s autoplayTwoSlides infinite linear;
}

@keyframes autoplayTwoSlides {
    0% {
        opacity: 0;
        z-index: -1;
    }

    2% {
        opacity: 1;
        z-index: 1;
    }

    50% {
        opacity: 1;
        z-index: 1;
    }

    52% {
        opacity: 0;
        z-index: -1;
    }

    100% {
        opacity: 0;
        z-index: -1;
    }
}

/* ===== Three Slides ===== */
.sitewide-banner .sitewide-banner-slide:first-child:nth-last-child(3),
.sitewide-banner .sitewide-banner-slide:first-child:nth-last-child(3) ~ .sitewide-banner-slide {
    animation: 12s autoplayThreeSlides infinite linear;
}

@keyframes autoplayThreeSlides {
    0% {
        opacity: 0;
        z-index: -1;
    }

    2% {
        opacity: 1;
        z-index: 1;
    }

    33.33% {
        opacity: 1;
        z-index: 1;
    }

    35.33% {
        opacity: 0;
        z-index: -1;
    }

    100% {
        opacity: 0;
        z-index: -1;
    }
}

/* ===== Four Slides ===== */
.sitewide-banner .sitewide-banner-slide:first-child:nth-last-child(4),
.sitewide-banner .sitewide-banner-slide:first-child:nth-last-child(4) ~ .sitewide-banner-slide {
    animation: 16s autoplayFourSlides infinite linear;
}

@keyframes autoplayFourSlides {
    0% {
        opacity: 0;
        z-index: -1;
    }

    2% {
        opacity: 1;
        z-index: 1;
    }

    25% {
        opacity: 1;
        z-index: 1;
    }

    27% {
        opacity: 0;
        z-index: -1;
    }

    100% {
        opacity: 0;
        z-index: -1;
    }
}

/* ===== Five Slides ===== */
.sitewide-banner .sitewide-banner-slide:first-child:nth-last-child(5),
.sitewide-banner .sitewide-banner-slide:first-child:nth-last-child(5) ~ .sitewide-banner-slide {
    animation: 20s autoplayFiveSlides infinite linear;
}

@keyframes autoplayFiveSlides {
    0% {
        opacity: 0;
        z-index: -1;
    }

    2% {
        opacity: 1;
        z-index: 1;
    }

    20% {
        opacity: 1;
        z-index: 1;
    }

    22% {
        opacity: 0;
        z-index: -1;
    }

    100% {
        opacity: 0;
        z-index: -1;
    }
}
